
import { defineComponent, reactive } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import useToggle from '@/composable/useToggle'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoInput: Input,
    FeildInputGroup
  },
  setup() {
    const { show: showForm, toggle: toggleForm } = useToggle(false)

    // Form 的初始值
    const initial = {
      SiteName: 'Taipei Office',
      Ipv4BroadcastLimit: 5000,
      Ipv6MulticastLimit: 5000
    }

    // 顯示
    const general = reactive({ ...initial })
    // 表單中顯示
    const generalFormData = reactive({ ...initial })

    const resetFormData = () => {
      Object.assign(generalFormData, general)
    }

    // 提交表單
    // 注意！Object assign 為淺拷貝
    // CHECK: (https://stackoverflow.com/questions/61184749/how-to-properly-reset-vue-composition-apis-reactive-values)
    const submitForm = event => {
      event.preventDefault()
      Object.assign(general, generalFormData)

      // 等待打完 API...
      toggleForm() // 關閉表單
      resetFormData() // 重設表單
    }

    return {
      showForm,
      toggleForm,
      general,
      generalFormData,
      submitForm
    }
  }
})
