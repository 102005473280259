<template>
  <div id="view-setting">
    <!-- 上方：標題 -->
    <Topbar :backTo="{ name: 'Site' }">Taipei Office 區域設定</Topbar>
    <div class="content-container">
      <!-- 左側：次導覽列 -->
      <SideNav>
        <router-link
          class="side-nav-link router-link-active"
          :to="{ name: 'EditGeneral', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GroupSetting', params: $route.params.id }"
          >網段群組設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPList', params: $route.params.id }"
          >註冊的 IP 派發來源</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'EditBlockMessage', params: $route.params.id }"
          >封鎖訊息 &amp; 驗證方式</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'ADAutoRegisterSetting', params: $route.params.id }"
          >AD 自動授權設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'RadiusSiteConfigSetting', params: $route.params.id }"
          >802.1x 設定</router-link
        >
      </SideNav>
      <div class="setting-container">
        <!-- 屬性設定：顯示初始資料 -->
        <div class="title-row">
          <h3>屬性設定</h3>
          <button
            class="btn form-btn-outline mr-2"
            v-show="!showForm"
            @click="toggleForm"
          >
            編輯
          </button>
        </div>
        <div v-show="!showForm">
          <div class="feild-row">
            <p class="feild-name">區域名稱</p>
            <p class="feild-value">{{ general.SiteName }}</p>
          </div>
          <div class="feild-row">
            <p class="feild-name">IPv4 廣播超量設定</p>
            <p class="feild-value">
              {{ general.Ipv4BroadcastLimit }}<span> (Bytes / Sec)</span>
            </p>
          </div>
          <div class="feild-row">
            <p class="feild-name">IPv6 群播超量設定</p>
            <p class="feild-value">
              {{ general.Ipv6MulticastLimit }} <span> (Bytes / Sec)</span>
            </p>
          </div>
        </div>
        <!-- 屬性設定：表單 -->
        <div v-show="showForm" class="form-wrapper">
          <form>
            <div class="single-feild-group">
              <label class="feild-name">區域名稱</label>
              <FeildInputGroup :isInvalid="true" :errorMsg="'輸入錯誤'">
                <KendoInput
                  :type="'text'"
                  style="width: 279px;"
                  v-model="generalFormData.SiteName"
                  :required="true"
                />
              </FeildInputGroup>
            </div>
            <div class="single-feild-group">
              <label class="feild-name">IPv4 廣播超量設定</label>
              <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
                <KendoInput
                  :type="'number'"
                  style="width: 279px;"
                  v-model="generalFormData.Ipv4BroadcastLimit"
                  :required="true"
                />
              </FeildInputGroup>
            </div>
            <div class="single-feild-group border-0">
              <label class="feild-name">IPv6 群播超量設定</label>
              <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
                <KendoInput
                  :type="'number'"
                  style="width: 279px;"
                  v-model="generalFormData.Ipv6MulticastLimit"
                  :required="true"
                />
              </FeildInputGroup>
            </div>
            <div class="footer-group">
              <button
                type="submit"
                class="btn btn-primary form-btn mr-2"
                @click="submitForm"
              >
                完成設定
              </button>
              <button class="btn form-btn-outline" @click.prevent="toggleForm">
                取消
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'
import { Input } from '@progress/kendo-vue-inputs'
import useToggle from '@/composable/useToggle'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoInput: Input,
    FeildInputGroup
  },
  setup() {
    const { show: showForm, toggle: toggleForm } = useToggle(false)

    // Form 的初始值
    const initial = {
      SiteName: 'Taipei Office',
      Ipv4BroadcastLimit: 5000,
      Ipv6MulticastLimit: 5000
    }

    // 顯示
    const general = reactive({ ...initial })
    // 表單中顯示
    const generalFormData = reactive({ ...initial })

    const resetFormData = () => {
      Object.assign(generalFormData, general)
    }

    // 提交表單
    // 注意！Object assign 為淺拷貝
    // CHECK: (https://stackoverflow.com/questions/61184749/how-to-properly-reset-vue-composition-apis-reactive-values)
    const submitForm = event => {
      event.preventDefault()
      Object.assign(general, generalFormData)

      // 等待打完 API...
      toggleForm() // 關閉表單
      resetFormData() // 重設表單
    }

    return {
      showForm,
      toggleForm,
      general,
      generalFormData,
      submitForm
    }
  }
})
</script>

<style scoped lang="scss"></style>
